export const defaultFooterText = `*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825. 

1 Information provided in this graph is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom Debt Relief” curve and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states. 

The “Current” bar assumes the payment of the estimated current total minimum monthly payment. Your total minimum monthly payment, which is the total payment due to your creditors each month for your total credit card debt, is estimated based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time. 

The “Current” bar also assumes the payment of the estimated current total minimum monthly payment. The estimated total minimum monthly payment, which is the total payment due to your creditors each month for your credit card debt, is an assumption based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time. 

When a minimum payment is estimated: Estimated current total minimum monthly payment is the total payment due to your creditors each month. 

When a time to pay off debt is estimated: Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated current total minimum monthly payment due to your creditors each month. 

When a total amount (principal, interest and fees) is estimated: Total estimated amount you will pay in principal, interest and fees over time if you pay only the estimated current total minimum monthly payment due to your creditors each month.

2 Actual client of Freedom Debt Relief. Client’s endorsement is a paid testimonial. Individual results are not typical and will vary.

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry. 

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`

export const homeVariationFooterText = `*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825.

**This guarantee takes effect when you graduate the program—or even if you leave before graduating. When you exit the program, we’ll use the combined total of all the debts you enrolled and settled and the fees you paid to us to calculate your eligibility for a refund. This guarantee covers all debt accounts you enroll as long as you don’t withdraw the account, take advances on the account, or add more debt to the account. This is not a savings guarantee, but a promise that we’ll refund up to 100% of our collected fees if you don’t save money on your enrolled debts through our program.

1 Information provided in this chart is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom” table and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states.

The “Current” column assumes the payment of the estimated current total minimum monthly payment. Your total minimum monthly payment, which is the total payment due to your creditors each month for your total credit card debt, is estimated based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time.

Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated total minimum monthly payment due to your creditors each month.

These estimates are not actual amounts or periods and are based on information you have provided, information from your credit report and/or information from third parties. Estimates are not for general use and are an approximation and not a guarantee. These are assumptions based on our prior experience and publicly available information on interest rates, charges and minimum payment requirements for consumer debt, such as, finance charges that accrue at rates ranging from 0% to 29.99% (22.77% for current credit cards and 29.99% for delinquent credit cards) and blending these rates with any other fixed term repayment and high interest loans you may have. Your actual rates, charges, fees and minimum payment requirements may be higher or lower. Actual estimates could also vary based on receipt of military benefits (SCRA and/or MLA).

These estimates assume that there are no additional charges, rebates, refunds, payments or reductions of your debt other than the monthly payment of the estimated current total minimum monthly payment. This payment strategy is based on publicly available information about your enrolled accounts and applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your enrolled debts over time.

2. Estimated current total minimum monthly payment is the total payment due to your creditors each month.

3. Total estimated amount you pay in principal, interest and fees over time if you pay only the estimated current total minimum monthly payment due to your creditors each month.

4. Actual client of Freedom Debt Relief. Client’s endorsement is a paid testimonial. Individual results are not typical and will vary. Program details including payment amount and time to resolve debt will vary.

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry.

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`

export const visualizationFooterText = `*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825.

**This guarantee takes effect when you graduate the program—or even if you leave before graduating. When you exit the program, we’ll use the combined total of all the debts you enrolled and settled and the fees you paid to us to calculate your eligibility for a refund.

1 Information provided in this graph is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom Debt Relief” curve and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states.

The “Current” curve and corresponding estimated monthly payment, debt cost, time to pay off, and savings amounts displayed are based on assumptions from the credit card debt amount you provided and do not include any other debts that you could have, such as a mortgage, auto loan, personal loan, etc. These estimates and assumptions are based on our prior experience and publicly available information on interest rates, charges, and minimum payment requirements for consumer debt. This includes an assumption that finance charges  accrue at rates ranging from 0% to 29.99% (22.16% for current credit cards and 29.99% for delinquent credit cards)Actual rates, charges, fees and minimum payment requirements may be higher or lower if any loan offer is provided. Actual estimates could also vary based on receipt of military benefits (SCRA and/or MLA).These assumptions also do not consider any other additional charges, rebates, refunds, payments or reductions of your debt.

The “Current” curve also assumes the payment of the estimated current total minimum monthly payment. The estimated total minimum monthly payment, which is the total payment due to your creditors each month for your credit card debt, is an assumption based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time.

Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated total minimum monthly payment due to your creditors each month.

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry.

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`

export const visualizationBarChartFooterText = `
*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825. 

**This guarantee takes effect when you graduate the program—or even if you leave before graduating. When you exit the program, we’ll use the combined total of all the debts you enrolled and settled and the fees you paid to us to calculate your eligibility for a refund. 

1 Information provided in this graph is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom Debt Relief” curve and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states. 

The “Current” bar assumes the payment of the estimated current total minimum monthly payment. Your total minimum monthly payment, which is the total payment due to your creditors each month for your total credit card debt, is estimated based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time.

The “Current” bar also assumes the payment of the estimated current total minimum monthly payment. The estimated total minimum monthly payment, which is the total payment due to your creditors each month for your credit card debt, is an assumption based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time. 

Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated total minimum monthly payment due to your creditors each month. 

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry. 

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`
export const spanishFooterText = `No todos los clientes pueden completar su programa por varias razones, incluida su capacidad para ahorrar fondos suficientes. Nuestras estimaciones se basan en resultados anteriores, que variarán según los específicos acreedores inscritos y los términos de su programa individual. No garantizamos que sus deudas se resolverán por un monto o porcentaje específico o dentro de un período de tiempo específico. No asumimos sus deudas, no hacemos pagos mensuales a los acreedores ni proporcionamos asesoramiento fiscal, bancarrota, contable o legal, ni servicios de reparación de crédito. Freedom Debt Relief no ofrece servicios de liquidación de deudas en todos los estados y las tarifas pueden variar de un estado a otro. En algunos estados, podemos remitirlo a un socio comercial de confianza que pueda brindarle servicios alternativos de alivio de la deuda. Comuníquese con un profesional de impuestos para analizar las posibles consecuencias impositivas de una resolución de deuda inferior al total. Lea y comprenda todos los materiales del programa antes de inscribirse. El uso de los servicios de liquidación de deudas probablemente afectará negativamente su solvencia crediticia, puede resultar en que usted esté sujeto a cobros o sea demandado por los acreedores o cobradores y puede aumentar los saldos pendientes de sus cuentas inscritas debido a la acumulación de comisiones e intereses. Sin embargo, los acuerdos negociados que obtenemos a su nombre resuelven toda la cuenta, incluidas todas las tasas e intereses adquiridos. C.P.D. Reg. No. T.S. 12-03825.

Freedom Debt Relief, LLC no discrimina por motivos de raza, color de piel, religión, sexo, estado civil, origen nacional o ascendencia.

Freedom Debt Relief se encuentra en 1875 S Grant St #400, San Mateo, CA 94402
`

export const footerTextVariation2 = `*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825. 

**This guarantee takes effect when you graduate the program—or even if you leave before graduating. When you exit the program, we’ll use the combined total of all the debts you enrolled and settled and the fees you paid to us to calculate your eligibility for a refund. This guarantee covers all debt accounts you enroll as long as you don’t withdraw the account, take advances on the account, or add more debt to the account. This is not a savings guarantee, but a promise that we’ll refund up to 100% of our collected fees if you don’t save money on your enrolled debts through our program. 

1 Information provided in this chart is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom” table and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states. 

The “Current” column assumes the payment of the estimated current total minimum monthly payment. Your total minimum monthly payment, which is the total payment due to your creditors each month for your total credit card debt, is estimated based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time. 

Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated total minimum monthly payment due to your creditors each month. 

These estimates are not actual amounts or periods and are based on information you have provided, information from your credit report and/or information from third parties. Estimates are not for general use and are an approximation and not a guarantee. These are assumptions based on our prior experience and publicly available information on interest rates, charges and minimum payment requirements for consumer debt, such as, finance charges that accrue at rates ranging from 0% to 29.99% (22.77% for current credit cards and 29.99% for delinquent credit cards) and blending these rates with any other fixed term repayment and high interest loans you may have. Your actual rates, charges, fees and minimum payment requirements may be higher or lower. Actual estimates could also vary based on receipt of military benefits (SCRA and/or MLA). 

These estimates assume that there are no additional charges, rebates, refunds, payments or reductions of your debt other than the monthly payment of the estimated current total minimum monthly payment. This payment strategy is based on publicly available information about your enrolled accounts and applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your enrolled debts over time. 

2. Estimated current total minimum monthly payment is the total payment due to your creditors each month. 

3. Total estimated amount you pay in principal, interest and fees over time if you pay only the estimated current total minimum monthly payment due to your creditors each month. 

4. Actual clients of Freedom Debt Relief. Client’s endorsement is a paid testimonial. Individual results are not typical and will vary. Program details including payment amount and time to resolve debt will vary. 

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry. 

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`

export const footerTextVariation5 = `*Our estimates are based on prior results, which will vary depending on your specific enrolled creditors and your individual program terms. Not all clients are able to complete their program for various reasons, including their ability to save sufficient funds. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states, including New Jersey, and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest. C.P.D. Reg. No. T.S.12-03825. 

**This guarantee takes effect when you graduate the program—or even if you leave before graduating. When you exit the program, we’ll use the combined total of all the debts you enrolled and settled and the fees you paid to us to calculate your eligibility for a refund. This guarantee covers all debt accounts you enroll as long as you don’t withdraw the account, take advances on the account, or add more debt to the account. This is not a savings guarantee, but a promise that we’ll refund up to 100% of our collected fees if you don’t save money on your enrolled debts through our program. 

1 Information provided in this chart is for informational purposes only and the Freedom Debt Relief option does not constitute an offer for debt resolution or any other product. The “Freedom” table and corresponding program terms (estimated monthly deposit, cost, and length) are based on the median offer of debt resolution offers during the preceding 90-day period for the amount of credit card debt you have entered. Any actual terms will vary and this product is not available in all states. 

The “Current” column assumes the payment of the estimated current total minimum monthly payment. Your total minimum monthly payment, which is the total payment due to your creditors each month for your total credit card debt, is estimated based on the credit card balance you have provided, the assumed finance charge discussed above, and a principal pay down percentage based on publicly available information. This payment strategy applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your credit card debt over time. 

Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay the estimated total minimum monthly payment due to your creditors each month. 

These estimates are not actual amounts or periods and are based on information you have provided, information from your credit report and/or information from third parties. Estimates are not for general use and are an approximation and not a guarantee. These are assumptions based on our prior experience and publicly available information on interest rates, charges and minimum payment requirements for consumer debt, such as, finance charges that accrue at rates ranging from 0% to 29.99% (22.77% for current credit cards and 29.99% for delinquent credit cards) and blending these rates with any other fixed term repayment and high interest loans you may have. Your actual rates, charges, fees and minimum payment requirements may be higher or lower. Actual estimates could also vary based on receipt of military benefits (SCRA and/or MLA). 

These estimates assume that there are no additional charges, rebates, refunds, payments or reductions of your debt other than the monthly payment of the estimated current total minimum monthly payment. This payment strategy is based on publicly available information about your enrolled accounts and applies the minimum monthly payment as a fixed amount until the balance has been paid in full. This is a fixed monthly payment amount which may differ from the actual required minimum payments on your enrolled debts over time. 

2 Estimated current total minimum monthly payment is the total payment due to your creditors each month. 

3 Total estimated amount you pay in principal, interest and fees over time if you pay only the estimated current total minimum monthly payment due to your creditors each month. 

4 Actual clients of Freedom Debt Relief. Client’s endorsement is a paid testimonial. Individual results are not typical and will vary. Program details including payment amount and time to resolve debt will vary. 

Freedom Debt Relief, LLC does not discriminate on the basis of race, color, religion, sex, marital status, national origin, or ancestry. 

Freedom Debt Relief is located at 1875 S Grant St #400, San Mateo, CA 94402
`
